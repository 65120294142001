import "./contactCard.css"
import linkedIn from "../logos/linkedin (1).png"
import gmail from "../logos/gmail.png"
import twitter from "../logos/twitter.png"
import cv from "../logos/cv.png"
import git from "../logos/github.png"
import React from 'react'

const ContactCard = () => {
    return (
        <div className="contactCardCon">
          <div className="contactGrid">   
        <img className="Logo"  src={git} alt="githunb logo" /> 
        <a className="contactText" href='https://github.com/wesleykel'> Check out what i'm working on, on GITHUB </a>
        </div>
        
         <div className="contactGrid">   
        <img className="Logo"  src={linkedIn} alt="linkedin logo" /> 
        <a className="contactText" href='https://www.linkedin.com/in/wesley-kelly/'> Connect on LinkedIn </a>
        </div>
        <div className="contactGrid">   
        <img className="Logo"  src={gmail} alt="gmail logo" /> 
        <a className="contactText" href='mailto:wesley.kelly@hotmail.com'> Send me an Email</a>
        </div>
        <div className="contactGrid">   
        <img className="Logo"  src={twitter} alt="twitter logo" /> 
        <a className="contactText" href='https://twitter.com/wesley_dev_'> Follow my journey on Twitter</a>
        </div>
        <div className="contactGrid">   
        <img className="Logo"  src={cv} alt="cv logo" /> 
        <a className="contactText" download href = "https://res.cloudinary.com/food-story/image/upload/v1647512917/Portfolio%20pics/01_Wesley_Kelly_CV_2022_etkgzh.pdf">Download my CV</a>
        </div>
        
        
        </div>
    )
}

export default ContactCard