const projects = [
    {picture:"https://res.cloudinary.com/food-story/image/upload/v1647697578/Portfolio%20pics/Untitled_design_11_fchoio.png",
    title:"Burger Kitchen",
    about:"Burger Kitchen was my first attempt of mixing the power of CSS with Javascript, simple fun app to show the  versitality of CSS.",
    link:"https://burger-kitchen-wesley.netlify.app/"
    },
  
    {picture:"https://res.cloudinary.com/food-story/image/upload/v1647696402/Portfolio%20pics/Untitled_design_7_kjkbuc.png",
    title:"Food-Story",
    about:"Final full stack application for the School of Code 16 week Boot camp, Worked in an agile team of 5 developers to build an application using the PERN stack, Using Disney Ideation we came up with an idea and plan to achieve our MVP. I created the navigation bar and video front page, I also worked in pairs to implement the Auth0 user authentication as well as the Cloudinary Photo uploads using the inbuilt NEXT.js routers.  ",
    link:"https://food-story.netlify.app/"
    },
    {picture:"https://res.cloudinary.com/food-story/image/upload/v1668608583/Portfolio%20pics/2_iujmod.png",
    title:"Calculator",
    about:"Calculator built with React using Redux for state managment",
    aboutNew:"",
    link:"https://calculator-redux-wes.netlify.app/"
    },

    {picture:"https://res.cloudinary.com/food-story/image/upload/v1648895262/Portfolio%20pics/Untitled_design_14_inlncf.png",
    title:"Get Events",
    about:`My First solo Full Stack Application, Using the MERN Stack (MongoDB, Express, React and NodeJS) I created  an App that allowed  users  to search for social events in any city using the Ticketmaster API and  save their favourite events on their own user profile using MongoDB.`, 
    aboutNew:"I used Auth0 to authenticate users and some Chakra UI components to style to the project. ",
    link:"https://getevents2-1-wesleykel.vercel.app/"
    },

    {picture:"https://res.cloudinary.com/food-story/image/upload/v1668088746/Portfolio%20pics/1_wkhcqp.png",
    title:"Todo List",
    about:`Full Stack Todo List, Front end built using Next JS and Typescript, backend is Node JS and Express with a Postres SQL database.`, 
    aboutNew:"",
    link:"https://todo-take21.vercel.app"
    },

    {picture:"https://res.cloudinary.com/food-story/image/upload/v1652527233/Portfolio%20pics/Untitled_design_16_vtlceb.png",
    title:"Weather-App",
    about:`React weather forecast application made using Open Weather API.`, 
    aboutNew:"",
    link:"https://weather120.netlify.app/"
    },


]

export default projects