import React from 'react';
import "./intro.css";
import pdf from "../logos/pdf-file.png"



const Intro = () => {
    


  return(<div className="introCon" id="intro">
  <div className='introContent'>
<h1 className="introText" id="hello">Hello I'm Wesley Kelly </h1>
<p className="introText2">A London based Software Engineer</p>

<a download href = "https://res.cloudinary.com/food-story/image/upload/v1647512917/Portfolio%20pics/01_Wesley_Kelly_CV_2022_etkgzh.pdf">
<div className='centerButton'   >
<button class="animate__animated animate__bounce" className='downloadButton'>Download my CV <img className='pdflogo' alt="pdf logo" src={pdf}></img></button>
</div>
</a>
</div>
  </div>

  )





}

 






export default Intro