import React from 'react'
import ContactCard from "../6contactMeCard"

import "./newContact.css"

const NewContactPage = () => {
    return (
        <div className="newConWrapper">
            <h3 id="contactmee"> Contact Information</h3>
        
        <ContactCard/>
        
       {/* <footer id="footer"> Page designed and made by WK2022 - Twitter icons created by Md Tanvirul Haque, Gmail icons created by Pixel perfect,  Linkedin icons created by riajulislam - Flaticon  </footer>*/}
        
        </div>
    )
}

export default NewContactPage