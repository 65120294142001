import React from 'react'
//import "./newProjects.css"
import "./pp2.css"
import projects from '../projects/projects'
import ProjectCard from '../3_1Project Card'
import Carousel from 'nuka-carousel';
const CaraProjects = () => {
    return (
        <div id="projects" >
     <h3 id="heading" >Projects</h3>

 <div className="wrapper">      
<Carousel
className='caroselFrame'
wrapAround={true}
    slidesToShow={3}
   autoplay={true}
   autoplayInterval={10000}
   cellAlign={'right'}
 >
{projects.map((items)=>{


return <ProjectCard title={items.title} img={items.picture} about={items.about} about2={items.aboutNew} link={items.link} />

})}
</Carousel></div>
<div className="grid">
{projects.map((items)=>{


return <ProjectCard title={items.title} img={items.picture} about={items.about} about2={items.aboutNew} link={items.link} />

})}
</div>
        </div>
    )
}

export default CaraProjects