
import CaraProjects from '../components/CarouselProjPage/pp2';
import './App.css';
import Navbar from '../components/0Navbar';
import Intro from '../components/1intro';
//import AboutMe from '../components/2Aboutme';
// import NewProjects from '../components/newProjectPage/newProjectpage';
import NewContactPage from '../components/NewContactPage/NewContact';
import 'animate.css';


function App() {
  return (
    <div className="App">
 
 <Navbar/>
 <Intro/> 
 {/*<AboutMe/> */}
{/* <NewProjects/> */}
<CaraProjects/>
 <NewContactPage/> 

    </div>
  );
}

export default App;
