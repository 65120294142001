import React from 'react'
import { Link } from 'react-scroll'


import "./navbar.css"


const Navbar = () => {
    return (
        <ul id="home" className="navbarCon">

        <li className='navItemOuter' ><Link 
        className='navItem' 
        activeClass='activeNav'
            to="intro"
           
            smooth={true}
            duration={500}
        
        >Home</Link></li>
           
           
           {/* <li className='navItemOuter'><Link
            activeClass='activeNav'
            to="aboutme"
            spy={true}
            smooth={true}
            duration={500}
            className="navItem"
            >About</Link></li>*/}

            <li className='navItemOuter'><Link 
            className='navItem'
            activeClass='activeNav'
            to="projects"
            spy={true}
            smooth={true}
            duration={500}
            
            >Projects</Link></li>

           <li className='navItemOuter'><Link 
            className='navItem'
            activeClass='activeNav'
            to="contactmee"
            spy={true}
            smooth={true}
            duration={500}>Contact </Link></li>
           
          
           
        </ul>
    )
     
}

export default Navbar