import "./projectCard.css"
import React from 'react'



const ProjectCard = ({ title ,img, about , link, about2}) => {
    return (
        <div className="projectCardCon">

<div className="probutCon">
<img className="projectPic" src={img} alt={title}/>
</div>
<h3 className="pcTitle">{title}</h3>
<p className="pcText">{about}</p>
<p className="pcText">{about2}</p>
<a className="pcLink" href={link}>

<button className="projectButton" type="submit">Click here to demo {title}</button>
</a>




</div>
            
      
    )
}

export default ProjectCard